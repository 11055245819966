// Import jQuery and Bootstrap
import $ from "jquery";
window.$ = $;
window.jQuery = $;

import "../webflow";

import React from "react";
import ReactDOM from "react-dom/client";

// Wait for the page to load before mounting React components
document.addEventListener("DOMContentLoaded", () => {
    console.log("DOM fully loaded and parsed. Mounting React components...");

    document.querySelectorAll("[data-react-component]").forEach((el) => {
        const componentName = el.getAttribute("data-react-component");
        if (!componentName) return;

        // Parse the data-props attribute
        const props = el.getAttribute("data-props");
        const parsedProps = props ? JSON.parse(props) : {};

        // Ensure the file extension is added explicitly
        import(`../components/${componentName}.tsx`)
            .then((module) => {
                const Component = module.default;
                const root = ReactDOM.createRoot(el);
                root.render(<Component {...parsedProps} />);
            })
            .catch((error) => {
                console.error(`Error loading React component "${componentName}":`, error);
            });
    });

    console.log("All React components have been mounted!");
});

console.log("Application.js is running with React support!");
